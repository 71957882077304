(function() {
    'use strict';

    angular
        .module('iseekApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        var today = new Date(),
            dayOfWeek = Math.max(today.getDay(), 1),
            themeId = (dayOfWeek + (dayOfWeek % 2)) / 2;

        $stateProvider.state('search', {
                abstract: true,
                parent: 'app',
                url: '/search',
                params: {
                    q: {
                        dynamic: true
                    },
                    target:null
                },
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/search/search.html',
                        controller: 'SearchController',
                        controllerAs: 'vm'
                    },
                    'nav-input': {
                        templateUrl: 'app/search/search.form.html',
                        controller: 'SearchController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('search.web', {
                url: '/web?q&target',
                templateUrl: 'app/main/search.html',
                params: {
                    q: {
                        dynamic: true
                    },
                    target: null
                },
                data: {
                    blade: 'core-web',
                    bodyClass: 'body-web-' + themeId
                },
                views: {
                    'content@': {
                        templateUrl: 'app/search/search.html',
                        controller: 'SearchController',
                        controllerAs: 'vm'
                    },
                    'nav-input': {
                        templateUrl: 'app/search/search.form.html',
                        controller: 'SearchController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('search.medical', {
                url: '/medical?q',
                templateUrl: 'app/main/search.html',
                params: {
                    q: {
                        dynamic: true
                    },
                },
                data: {
                    blade: 'core-medical',
                    bodyClass: 'body-medical-' + themeId
                },
                views: {
                    'content@': {
                        templateUrl: 'app/search/search.html',
                        controller: 'SearchController',
                        controllerAs: 'vm'
                    },
                    'nav-input': {
                        templateUrl: 'app/search/search.form.html',
                        controller: 'SearchController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('search.education', {
                url: '/education?q',
                templateUrl: 'app/main/search.html',
                params: {
                    q: {
                        dynamic: true
                    },
                },
                data: {
                    blade: 'core-education',
                    bodyClass: 'body-education-' + themeId
                },
                views: {
                    'content@': {
                        templateUrl: 'app/search/search.html',
                        controller: 'SearchController',
                        controllerAs: 'vm'
                    },
                    'nav-input': {
                        templateUrl: 'app/search/search.form.html',
                        controller: 'SearchController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('search.finance', {
                url: '/finance?q&target',
                templateUrl: 'app/main/search.html',
                params: {
                    q: {
                        dynamic: true
                    },
                },
                data: {
                    blade: 'core-finance',
                    bodyClass: 'body-finance-' + themeId
                },
                views: {
                    'content@': {
                        templateUrl: 'app/search/search.html',
                        controller: 'SearchController',
                        controllerAs: 'vm'
                    },
                    'nav-input': {
                        templateUrl: 'app/search/search.form.html',
                        controller: 'SearchController',
                        controllerAs: 'vm'
                    }
                }
            });
    };

})();
