(function() {

    angular
        .module('iseekApp')
        .controller('TargetController', TargetController);

    TargetController.$inject = ['$scope', '$uibModalInstance', '$cookieStore', 'targets'];

    function TargetController ($scope, $uibModalInstance, $cookieStore, targets) {

        $scope.targets = targets;
        $scope.error = null;

        $scope.addTarget = function() {
            if (!validTargets($scope.targets)) {
                $scope.error = "Invalid Domain or URL, please try again.";
            } else {
                $scope.error = null;
                var newItemNo = $scope.targets.length + 1;
                if ($scope.targets[$scope.targets.length - 1].value.length > 0
                    && newItemNo < 11) {

                    $scope.targets.push({'name': 'URL ' + newItemNo});
                }
            }
        };

        $scope.removeTarget = function() {
            var lastIndex = $scope.targets.length - 1;
            if ($scope.targets.length === 1) {
                $scope.targets[lastIndex].value = "";
            } else {
                $scope.targets.splice(lastIndex);
            }
        };

        $scope.submit = function () {
            if (validTargets($scope.targets)) {
                $scope.error = null;
                $uibModalInstance.close($scope.targets);
            } else {
                $scope.error = "Invalid Domain or URL, please try again.";
            }
        }

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }

    function validTargets(targets) {
        var valid = true;
        var numTargets = targets.length;
        if (!(numTargets === 1 && targets[0].value === '')) {
            for (var i = 0; i < numTargets; i++) {
                if (!validTarget(targets[i].value)) {
                    valid = false;
                    break;
                }
            }
        }
        return valid;
    }

    function validTarget(value) {
        var valid = true;
        if (value.indexOf("http") > -1) {
            valid = validateUrl(value);
        } else if (!/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(value)) {
            // Domain
            valid = false;
        }

        return valid;
    }

    function validateUrl(value) {
        return /^(?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
    }
})();
