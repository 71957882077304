(function() {
    'use strict';

    angular
        .module('iseekApp')
        .controller('SearchController', SearchController);

    SearchController.$inject = ['$rootScope', '$scope', '$state', '$location', 'TargetService'];

    function SearchController ($rootScope, $scope, $state, $location, TargetService) {
        var vm = this;

        $scope.viewName = 'search';
        $scope.$location = $location;
        vm.selectTarget = TargetService.open;
        vm.query = $location.search().q;
        vm.initState = null;

        $scope.$on('selectTargetSuccess', function() {
            vm.initState = null;
            $state.go($state.current.name, {"q": vm.query, "target": TargetService.getTargetStr()});
        });

        // Initialize embedded search during browser refresh.
        $rootScope.$on('$viewContentLoaded', function(event) {
            if (vm.initState === null && window.location.href.indexOf("q=") > -1) {
                vm.initState = event.targetScope.toState.name;
                init();
                console.log("Reset for first view state = " + vm.initState);
            }
        });


        // Initialize embedded search during change of domain.
        $scope.$on('$locationChangeSuccess',
            function(event) {
                if (event.targetScope.toState.name !== vm.initState) {
                    vm.initState = event.targetScope.toState.name;
                    init();
                    console.log("Reset for new view state = " + vm.initState);
                }
        });

        function init() {

            angular.element("#iSeekSearchButton")[0].children[0].style.display = "none";
            angular.element("#iSeekSearchButton")[0].children[1].style.display = "inline-block";

            ISEEKAPI.initEmbed({
                apiBase : ($location.host() === 'localhost' ? "//localhost:9090/iseek-core/" : "//api" + $location.host().substring($location.host().indexOf("."), $location.host().length) + "/"), /* iseek.com */
                key : $rootScope.toState.data.blade,
                "layout" : {
                    "themes": {
                        "default": "iseek.com",
                        "iseek.com": {
                            css : ["//" + $location.host() + ($location.port() === 80 ? "" : ":" + $location.port()) + "/content/static_css/embedded.search.css"],
                            "primary-color":"#5A5758",
                            "secondary-color":"#FF781D"
                        }
                    }
                },
                "target" : ($state.params.target ? $state.params.target : "")
            }, function(ISEEKAPI){

                angular.element("#iSeekSearchButton")[0].children[0].style.display = "inline-block";
                angular.element("#iSeekSearchButton")[0].children[1].style.display = "none";

                // Intercept history change from embedded search to update local q in state.
                window.addEventListener('message', function(e) {
                    if (e.data) {
                        var dataObj = JSON.parse(e.data);
                        if (dataObj.iseekEvent === "onHistoryStatePushed.iseek") {
                            $state.go($state.current, {q: dataObj.iseekEventArgs[0].query, "target": TargetService.getTargetStr()}, {notify: false})
                        }
                    }
                });

            });
        }
    }
})();
