(function() {
    'use strict';

    angular
        .module('iseekApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        var today = new Date(),
            dayOfWeek = Math.max(today.getDay(), 1),
            themeId = (dayOfWeek + (dayOfWeek % 2)) / 2;

        $stateProvider.state('home', {
            parent: 'app',
            url: '/',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/home/home.html',
                    controller: 'HomeController',
                    controllerAs: 'vm'
                }
            }
        })
        .state('home.web', {
            url: 'web',
            templateUrl: 'app/home/home.html',
            controller: 'HomeController',
            controllerAs: 'vm',
            data: {
                authorities: [],
                targetState: 'search.web',
                bodyClass: 'body-web-' + themeId
            }
        })
        .state('home.medical', {
            url: 'medical',
            templateUrl: 'app/home/home.html',
            controller: 'HomeController',
            controllerAs: 'vm',
            data: {
                authorities: [],
                targetState: 'search.medical',
                bodyClass: 'body-medical-' + themeId
            }
        })
        .state('home.education', {
            url: 'education',
            templateUrl: 'app/home/home.html',
            controller: 'HomeController',
            controllerAs: 'vm',
            data: {
                authorities: [],
                targetState: 'search.education',
                bodyClass: 'body-education-' + themeId
            }
        })
        .state('home.finance', {
            url: 'finance',
            templateUrl: 'app/home/home.html',
            controller: 'HomeController',
            controllerAs: 'vm',
            data: {
                authorities: [],
                targetState: 'search.finance',
                bodyClass: 'body-finance-' + themeId
            }
        });
    }
})();
