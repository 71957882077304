(function() {
    'use strict';

    angular
        .module('iseekApp')
        .factory('TargetService', TargetService);

    TargetService.$inject = ['$rootScope', '$uibModal', '$cookieStore'];

    function TargetService ($rootScope, $uibModal, $cookieStore) {
        var service = {
            open: open,
            getTargets : getTargets,
            getTargetStr : getTargetStr
        };

        var modalInstance = null;
        var resetModal = function () {
            modalInstance = null;
        };

        return service;

        function getTargets() {
            var targets = $cookieStore.get("target");
            if (!targets || targets.length === 0) {
                targets = [{name: "URL 1", value: ""}];
            }
            return targets;
        }

        function getTargetStr() {
            var targetStr = "";
            var targets = getTargets();
            if (targets && targets.length > 0 && targets[0].value.length > 0) {
                targets.forEach( function (target) {
                    targetStr = targetStr + "," + target.value;
                });
                if (targetStr.length > 0) {
                    targetStr = targetStr.substr(1);
                }
            }
            return targetStr;
        }

        function open() {
            if (modalInstance !== null) return;
            modalInstance = $uibModal.open({
                animation: true,
                backdrop: true,
                windowClass: 'modal',
                templateUrl: 'app/components/target/target.html',
                controller: 'TargetController',
                controllerAs: 'vm',
                resolve: {
                    targets: function () {
                        return getTargets();
                    }
                }
            });
            modalInstance.result.then(
                function(targets) {
                    $cookieStore.put("target", targets);
                    $rootScope.$broadcast("selectTargetSuccess", targets);
                    resetModal();
                },
                resetModal
            );
        }
    }
})();
