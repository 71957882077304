(function() {

    angular
        .module('iseekApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', 'LoginService', 'TargetService', '$state', '$location'];

    function HomeController ($scope, Principal, LoginService, TargetService, $state, $location) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.submitSearch = submitSearch;
        vm.login = LoginService.open;
        vm.selectTarget = TargetService.open;
        vm.register = register;

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();
        refreshDomain();

        $scope.webActive = function() {
            return $state.includes("home.web");
        }

        $scope.medicalActive = function() {
            return $state.includes("home.medical");
        }

        $scope.educationActive = function() {
            return $state.includes("home.education");
        }

        $scope.financeActive = function() {
            return $state.includes("home.finance");
        }
        
        function getAccount() {
            /*Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });*/
        }
        function register () {
            $state.go('register');
        }

        function submitSearch(targetState) {
            if ($scope.query && $scope.query.length > 0) {
                $state.go(targetState, {"q": $scope.query, "target": TargetService.getTargetStr()});
            }
        }

        function refreshDomain() {

            var host = $location.host();
            var state = "home.web";
            if (host.startsWith("education")
                || host.startsWith("k12")) {

                state = "home.education";
            } else if (host.startsWith("med")) {
                state = "home.medical";
            } else if (host.startsWith("finance")) {
            	state = "home.finance";
            }
            $state.go(state);
        }
    }
})();
